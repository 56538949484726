import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
    key: '3vnt',
    storage: window.localStorage,
    reducer: (state) => ({ 
        code: state.code,
        pin: state.pin,
        event: state.event,
        attendees: state.attendees
    })
});

const plugins = [vuexLocal.plugin];

export default new Vuex.Store({
    state: {
        code: '',
        pin: '',
        event: {},
        attendees: []
    },
    mutations: {
        SET_EVENT (state, payload){
            state.event = payload;
        },
        SET_CODE (state, payload){
            state.code = payload;
        },
        SET_PIN (state, payload){
            state.pin = payload;
        },
        CLEAR (state){
            state.event = {};
            state.pin = '';
            state.code = '';
            state.attendees = [];
        }
    },
    getters: {
        eventID: state => {
            if (state.event && state.event.id){
                return state.event.id;
            } else {
                return '';
            }
        },
        event: state => {
            if (state.event && state.event.id){
                return state.event;
            } else {
                return null;
            }
        }
    },
    actions: {
        GET_EVENT({ commit }, payload){
            return new Promise((resolve, reject) => {
                window.axios.get('/api/app/event/'+payload.code+'/'+payload.pin).then(response => {
                    commit('SET_EVENT', response.data.data);
                    commit('SET_CODE', payload.code);
                    commit('SET_PIN', payload.pin);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
            })
        },
        CLEAR({ commit }){
            commit('CLEAR');
        }
    },
    plugins
})
