window.axios = require('axios');
window.axios.defaults.baseURL = 'https://3vnt.co';
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import Vue from 'vue';

import VueQrcodeReader from 'vue-qrcode-reader';

// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faS, faHome, faBarcode, faCircle, faUsers, faClipboardList, faExchange, faSearch, faCheckCircle, faTimesCircle, faBan } from '@fortawesome/free-solid-svg-icons';
//import all from '@fortawesome/free-regular-svg-icons';

library.add(faS, faHome, faBarcode, faCircle, faUsers, faClipboardList, faExchange, faSearch, faCheckCircle, faTimesCircle, faBan);
// library.add(regular);

Vue.component('font-awesome-icon', FontAwesomeIcon)

import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';

Vue.config.productionTip = false;

Vue.use(VueQrcodeReader);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
