<template>
    <div id="app">
        <router-view />
        <div id="nav" v-if="showToolbar">
            <router-link to="/"><font-awesome-icon :icon="['faS', 'faHome']" /><span>Home</span></router-link>
            <router-link to="/scan"><font-awesome-icon :icon="['faS', 'faBarcode']" /><span>Scan</span></router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'app',
    computed: {
        showToolbar: function(){
            if (this.$store.getters.eventID){
                return true;
            } else {
                return false;
            }
        }
    }
}
</script>


<style>
    body {
        margin: 0px;
        padding: 0px;
        background: #16333F;
        overscroll-behavior: contain;
    }

    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    #nav {
        width: 100%;
        background: #EFEFEF;
        position: fixed;
        bottom: 0px;
        left: 0px;
        right: 0px;
        height: 60px;
    }

    #nav a {
        font-weight: bold;
        color: #2c3e50;
        height: 60px;
        line-height: 60px;
        width: 50%;
        display: inline-block;
        text-align: center;
        cursor: pointer;
        text-decoration: none;
        outline: none;
    }

    #nav a.router-link-exact-active {
        background: #CCCCCC;
    }

    #nav a span {
        font-size: 11px;
    }

    #nav .svg-inline--fa {
        display: block!important;
        font-size: 18px!important;
        width: 100% !important;
        padding-top: 10px;
        margin-bottom: -15px;
    }
</style>