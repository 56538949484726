<template>
    <div class="home">
        <img alt="3VNT Logo" src="../assets/3vnt.png" width="80%">
        <div class="load-event" v-if="!event">
            <div class="step-1" v-show="step == 1">
                <h2>Enter your Event Code</h2>
                <input type="text" v-model="code" name="code" placeholder="Event Code" />
                <button class="vnt-button" @click="step = 2">Next</button>
            </div>
            <div class="step-1" v-show="step == 2">
                <h2>Enter your Event Pin</h2>
                <div class="error_message" v-if="error_message">{{ error_message }}</div>
                <input type="text" v-model="pin" name="pin" placeholder="Event Pin" />
                <button class="vnt-button" @click="lookup">Connect</button>
                <button class="vnt-button black" @click="step = 1; error_message = '';">Back</button>
            </div>
        </div>
        <div class="event-loaded" v-else>
            <h2>{{ event.name }}</h2>
            <button class="vnt-button" @click="clear" style="margin-top: 2rem;"><font-awesome-icon :icon="['fas', 'exchange']" style="padding-right: 10px;" /> Change Event</button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'home-scan',
        data: function () {
            return {
                step: 1,
                code: '',
                pin: '',
                error_message: null
            }
        },
        components: {
            
        },
        methods: {
            lookup: function(){
                this.error_message = '';

                this.$store.dispatch('GET_EVENT', { code: this.code, pin: this.pin }).then((response) => {
                    if (!response.data.success){
                        this.error_message = response.data.message;
                    }
                });
            },
            clear: function(){
                this.error_message = '';
                this.$store.dispatch('CLEAR');
            }
        },
        computed: {
            event: function(){
                return this.$store.getters.event;
            }
        }
    }
</script>

    <style>
    .home {
        padding: 2rem;
        text-align: center;
    }

    .home img {
        max-height: 20%;
        height: 20%;
    }

    h1, h2, h3 {
        color: #FFF;
    }

    .load-event {
        margin-top: 4rem;
    }

    input[type='text'] {
        border: 0px;
        width: 100%;
        font-size: 24px;
        padding: 10px 0px;
        text-align: center;
    }

    input[type='text']::placeholder {
        color: #afafaf;
    }

    .vnt-button {
        width: 100%;
        background: #3FD0D2;
        border: 0px;
        color: #FFFFFF;
        font-size: 20px;
        padding: 10px;
        margin-top: 0.5rem;
        cursor: pointer;
    }

    .vnt-button.black {
        background: #051C28;
        color: #FFFFFF;
    }

    .home .error_message {
        color: #3FD0D2;
        background-color: #f2dede;
        border-color: #ebccd1;
        width: 100%;
        padding: 20px 0px;
        margin-bottom: 2rem;
    }
</style>